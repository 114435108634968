"use strict";
import { useTranslation } from "uniswap/src/i18n";
export const useMenuContent = () => {
  const { t } = useTranslation();
  return [
    {
      title: t("common.header1"),
      items: [
        { label: t("common.link1"), href: "https://boards.greenhouse.io/uniswaplabs" }
        // { label: t('common.blog'), href: 'https://blog.uniswap.org/' },
      ]
    }
    // {
    //   title: t('common.protocol'),
    //   items: [
    //     { label: t('common.vote'), href: 'https://vote.uniswapfoundation.org' },
    //     { label: t('common.governance'), href: 'https://uniswap.org/governance' },
    //     { label: t('common.developers'), href: 'https://uniswap.org/developers' },
    //   ],
    // },
    // {
    //   title: t('common.needHelp'),
    //   items: [
    //     { label: t('common.helpCenter'), href: 'https://support.uniswap.org/hc/en-us' },
    //     { label: t('common.contactUs.button'), href: 'https://support.uniswap.org/hc/en-us/requests/new' },
    //   ],
    // },
  ];
};
