"use strict";
import { noop } from "@tanstack/react-table";
import { useSwapCallback } from "hooks/useSwapCallback";
import useWrapCallback from "hooks/useWrapCallback";
import { Deadline } from "uniswap/src/features/transactions/swap/settings/configs/Deadline";
import { SwapFlow } from "uniswap/src/features/transactions/swap/SwapFlow";
const WEB_CUSTOM_SWAP_SETTINGS = [Deadline];
const Bridge = ({
  swapRedirectCallback,
  prefilledState
}) => {
  const swapCallback = useSwapCallback();
  const wrapCallback = useWrapCallback();
  return <SwapFlow
    customSettings={WEB_CUSTOM_SWAP_SETTINGS}
    hideHeader={false}
    hideFooter={false}
    onClose={noop}
    swapRedirectCallback={swapRedirectCallback}
    swapCallback={swapCallback}
    wrapCallback={wrapCallback}
    prefilledState={prefilledState}
  />;
};
export default Bridge;
