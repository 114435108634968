"use strict";
import { InterfaceElementName } from "@uniswap/analytics-events";
import UNIWALLET_ICON from "assets/wallets/uniswap-wallet-icon.png";
import Column from "components/deprecated/Column";
import Row from "components/deprecated/Row";
import { AppIcon, OptionContainer } from "components/WalletModal/UniswapWalletOptions";
import styled from "lib/styled-components";
import { useState } from "react";
import { colors } from "theme/colors";
import { Z_INDEX } from "theme/zIndex";
import { Text } from "ui/src";
import Trace from "uniswap/src/features/telemetry/Trace";
import { Trans } from "uniswap/src/i18n";
const LightBackground = styled.div`
  max-height: 72px;
  width: 100%;
  background: ${colors.surface1_light};
  background-color: ${colors.accent1_dark};
  border-radius: 16px;
  position: relative;
`;
const BackgroundImage = styled.div`
  // background: url(${({ backgroundImage }) => backgroundImage});
  background-color:${colors.accent1_dark};
  // background-repeat: no-repeat;
  // background-size: cover;
  // opacity: ${({ isHovered }) => isHovered ? 0.54 : 1};
  // max-height: 72px;
  // width: 100%;
  // height: 100%;
  // border-radius: 16px;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: ${Z_INDEX.deprecated_zero};
  // transition: opacity ${({ theme }) => theme.transition.duration.fast} ${({ theme }) => theme.transition.timing.inOut};
`;
export const DownloadWalletOption = () => {
  const openGetTheAppModal = () => {
  };
  const [optionHovered, setOptionHovered] = useState(false);
  return <Trace logPress element={InterfaceElementName.EXTENSION_DOWNLOAD_CONNECTOR}><LightBackground
    onMouseEnter={() => setOptionHovered(true)}
    onMouseLeave={() => setOptionHovered(false)}
    data-testid="download-uniswap-wallet"
  >
    {
      /* <BackgroundImage backgroundImage="/images/extension_promo/background_connector.png" isHovered={optionHovered} /> */
    }
    <OptionContainer onClick={openGetTheAppModal}>
      <AppIcon src={UNIWALLET_ICON} alt="uniswap-app-icon" />
      <Row gap="xs"><Column>
        <Text variant="buttonLabel2" color="$white" whiteSpace="nowrap"><Trans i18nKey="common.getUniswapWallet" /></Text>
        <Text variant="body4" color="$white" whiteSpace="nowrap"><Trans i18nKey="common.availableOnIOSAndroidMac" /></Text>
      </Column></Row>
    </OptionContainer>
  </LightBackground></Trace>;
};
