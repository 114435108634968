"use strict";
import DnergyLogo from "assets/images/logo.png";
import styled from "lib/styled-components";
import React from "react";
function Logo({ onClick }) {
  return <React.Fragment><img src={DnergyLogo} onClick={onClick} style={{ cursor: "pointer" }} /></React.Fragment>;
}
const Container = styled.div`
  position: relative;
  cursor: ${({ clickable }) => clickable ? "pointer" : "auto"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NavIcon = ({ clickable, onClick, ...props }) => <Container clickable={clickable}><Logo onClick={onClick} /></Container>;
